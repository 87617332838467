.flexSB {
    display: flex;
    align-items: center;
    justify-content: center;
}
.portfolio {
    position: relative;
    margin-top: 7rem;
}
.portfolio h1 {
    margin-top: 20px;
    font-size: 30px;
}
.portfolio li {
    margin-left: 30px;
    cursor: pointer;
    transition: 0.5s;
}
.portfolio .links li:hover,
.portfolio .links li.active {
    background-color: #013328;
    color: #e3dcd2;
    padding: 5px 10px;
}
.portfolio .links li {
    padding: 5px 10px;
    border-radius: 13px;
}
.portfolio img {
    height: 100%;
    cursor: pointer;
    margin: 0 auto;
    border-radius: 0px;
    -o-object-fit: fill;
    object-fit: fill;
}
.img {
    max-height: 345px;
    border-radius: 0px;
    overflow: hidden;
}
.portfolio-card {
    border-radius: 14px;
    color: #e3dcd2;
    overflow: hidden;
    height: 100%;
    border-radius: 14px;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    text-decoration: none;
    border-radius: 45px;
    background-color: #fff;
    box-shadow: 0 0 5px 0 rgba(39, 25, 77, 0.02),
        0 3px 20px 0 rgba(112, 81, 239, 0.04),
        0 10px 28px 0 rgba(39, 25, 77, 0.03);
}

.links {
    margin-top: 2rem;
}
.portfolio .content {
    grid-template-columns: auto auto auto;
}
@media screen and (max-width: 991px) {
    .portfolio .content {
        grid-template-columns: auto auto;
    }
    .links .flexSB {
        display: block;
    }
}
/* @media screen and (max-width: 767px) {
    .portfolio .content {
        grid-template-columns: auto;
    }
} */
.portfolio .box {
    position: relative;
    display: inline-block;
}
.portfolio .overlay h1 {
    margin: 0;
    font-size: 20px;
    color: white;
}
.portfolio .overlay {
    position: absolute;
    bottom: 20;
    width: 100%;
    height: 100%;
    margin-left: 0rem;
    padding: 20px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    top: 0;
    opacity: 0;
    transition: 0.5s;
    background-color: #013328;
    border-radius: 2rem;
}
.portfolio .box:hover .overlay {
    opacity: 1;
}

.cards-portfolio {
    display: grid;
    gap: 2rem;
}
@media (min-width: 600px) {
    .cards-portfolio {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (min-width: 991px) {
    .cards-portfolio {
        grid-template-columns: repeat(3, 1fr);
    }
}
.card.card-portfolio {
    overflow: hidden;
    height: 100%;
    border-radius: 14px;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    text-decoration: none;
    transition: 0.5s;
}
.card {
    border-radius: 45px;
    background-color: #fff;
    box-shadow: 0 0 5px 0 rgba(39, 25, 77, 0.02),
        0 3px 20px 0 rgba(112, 81, 239, 0.04),
        0 10px 28px 0 rgba(39, 25, 77, 0.03);
}
.w-inline-block {
    max-width: 100%;
    display: inline-block;
}
.image-wrapper.card-portfolio {
    max-height: 345px;
    border-radius: 0px;
}
.image-wrapper {
    overflow: hidden;
}
.image.card-portfolio {
    border-radius: 0px;
    -o-object-fit: fill;
    object-fit: fill;
}
@media screen and (max-width: 991px) {
    .card-portfolio-content {
        padding-bottom: 50px;
    }
}

.card-portfolio-content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: -15px;
    padding-right: 40px;
    padding-bottom: 70px;
    padding-left: 40px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
}
.portfolio-category-wrapper {
    position: relative;
    z-index: 1;
    margin-bottom: 21px;
    padding: 10px 18px;
    border-radius: 12px;
    background-color: #cc8b65;
}

.portfolio-category {
    font-size: 16px;
    text-transform: capitalize;
    line-height: 1.188em;
    font-weight: 500;

    color: #fff;
}
.portfolio-content-case-study-link {
    color: #013328;
    text-decoration: none;
}
@media screen and (max-width: 991px) {
    .title.card-portfolio {
        margin-bottom: 10px;
    }
    .card-potrfolio-paragraph {
        margin-bottom: 30px;
    }
}

.title.card-portfolio {
    margin-bottom: 12px;
}

.card-potrfolio-paragraph {
    display: none;
    margin-bottom: 40px;
    color: #013328;
}
.card-portfolio-case-study-link {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #cc8b65;
    line-height: 1.111em;
    font-weight: 500;
    letter-spacing: 0.02em;
    transition: 0.5s;
}
.card-portfolio-link-arrow-wrapper {
    display: -webkit-box;
    transition: 0.5s;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 33px;
    min-width: 33px;
    margin-left: 13px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 8px;
    background-color: #eff4ff;
    font-family: 'Icons Designer Template', sans-serif;
    line-height: 1em;
}
.arrow-icon {
    max-width: 20px;
    object-fit: contain;
}
.card.card-portfolio:hover .card-portfolio-link-arrow-wrapper {
    background-color: #013328;
    margin-left: 20px;
}

.card.card-portfolio:hover .card-portfolio-link-arrow-wrapper img {
    filter: brightness(0) invert(1);
}
.card.card-portfolio:hover {
    transform: scale(1.1);
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&family=Playfair+Display:wght@400;600&family=Poppins:wght@600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
html,
body,
#root,
.app {
    height: 100%;
    @apply text-black;
    background-color: #013328;
    font-family: Spacegrotesk, sans-serif;
    font-family: Spacegrotesk, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.667em;
}
a {
    border: 0;
    text-decoration: none !important;
}
h1 {
    font-size: 70px;
    font-weight: 700;
    line-height: 1.132em;
}
.w-100 {
    width: 100%;
}
section {
    max-width: 1500px;
    margin: auto;
}
.icon {
    max-width: 125px;
    margin: 0 auto;
    object-fit: contain;
}
.skills-img {
    max-height: 400px;
    margin: 0 auto;
    margin-top: 4rem;
}
.list-skills li {
}
.container {
    margin: 0 auto;
}
footer .footer-icon a img {
    filter: brightness(1);
}
.text-black {
    --tw-text-opacity: 1;
    color: #100c0d;
}

.text-salmon {
    --tw-text-opacity: 1;
    color: #cc8b65;
}

.text-forest-green {
    --tw-text-opacity: 1;
    color: #013328;
}
.text-beige {
    --tw-text-opacity: 1;
    color: #e3dcd2;
}
.border-beige {
    border-color: #e3dcd2;
}

.bg-forest-green {
    background-color: #013328;
}

.bg-beige {
    background-color: #e3dcd2;
}
.bg-salmon {
    background-color: #cc8b65;
}
.bg-black {
    background-color: #100c0d;
}
.btn-primary-salmon {
    background-color: #cc8b65;
    color: #e3dcd2;
    border-radius: 2rem;
    font-weight: 600;
    padding: 0.5rem 1rem;
    border: 2px solid #cc8b65;
}
.btn-primary-salmon:hover {
    background-color: #fff;
    color: #cc8b65;
    border: 2px solid #cc8b65;
}
.border-salmon {
    border-color: #cc8b65;
}
.border-forest-green {
    border-color: #013328;
}
h2 {
    margin-top: 0;
    margin-bottom: 16px;
    font-size: 38px;
    font-weight: 700;
    line-height: 1.447em;
}
h2 {
    margin-top: 20px;
    font-size: 32px;
    line-height: 36px;
}
.gap-row-64px {
    grid-row-gap: 64px;
}
.grid-2-columns {
    grid-column-gap: 28px;
    grid-row-gap: 28px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    align-items: center;
    display: grid;
}
.position {
    font-weight: 500;
    font-size: 24px;
    line-height: 1.083em;
    color: #e3dcd2b5;
}

.footer-link-wrapper {
    border-bottom: 3px solid #e3dcd2;
    width: fit-content !important;
    padding-bottom: 5px;
    color: #e3dcd2;
    margin-top: 16px !important;
    white-space: nowrap;
    transition: none;
    display: inline-block;
    position: relative;
    font-size: 22px;
    font-weight: 700;
    line-height: 1.273em;
    transition: color 0.3s;
    display: inline-block;
}
.subtitle {
    letter-spacing: 0.06em;
    text-transform: uppercase;
    margin-bottom: 24px;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.1em;
}
.arrow-img {
    width: 25px;
    object-fit: contain;
}
.display-5 {
    font-size: 34px !important;
    font-weight: 700 !important;
    line-height: 1.441em !important;
}
.display-3 {
    font-size: 52px !important;
    font-weight: 700 !important;
    line-height: 1.231em !important;
}
.text-300.bold {
    color: var(--neutral--100);
    font-weight: 700;
}
.text-300 {
    font-size: 20px;
    height: fit-content;
}
.mg-right-12px {
    margin-right: 12px;
}
.flex.align-center {
    align-items: center;
}
@media (min-width: 1200px) {
    .display-2 {
        font-size: 4.5rem !important;
    }
}
.emoji {
    font-size: 60px;
    margin-right: 1rem;
}

.display-2 {
    font-size: 60px !important;
    font-weight: 700 !important;
    line-height: 1.132em !important;
}
.heading-top-line.large {
    max-width: 148px;
    min-height: 8px;
    margin-bottom: 48px;
}
.heading-top-line {
    width: 100%;
    max-width: 118px;
    min-height: 5px;
    margin-bottom: 24px;
}
@media screen and (max-width: 991px) {
    .grid-2-columns._1-col-tablet {
        grid-template-columns: 1fr;
    }
}
.container-default {
    max-width: 1268px;
    padding-left: 24px;
    padding-right: 24px;
}
@media screen and (max-width: 991px) {
    .w-container {
        max-width: 728px;
    }
}

.w-container {
    max-width: 940px;
    margin-left: auto;
    margin-right: auto;
}
.container-default {
    max-width: 1268px;
    padding-left: 24px;
    padding-right: 24px;
}
p,
span,
a {
    font-size: 18px;
}
.text-item-center {
    text-align: center;
}

.expertise-cards {
    border: 1px solid #e3dcd2;
    padding: 1rem;
    border-radius: 2rem;
    color: #e3dcd2;
}
.cards {
    display: grid;
    gap: 2rem;
}

@media (min-width: 600px) {
    .cards {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (min-width: 900px) {
    .cards {
        grid-template-columns: repeat(3, 1fr);
    }
}
